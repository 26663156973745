<template>
  <div>
    <NavTitle class="mb16" title="意见反馈" :showBack="false">
      <template #right>
        <div class="input-row">
          <el-input class="input" placeholder="请输入用户名/手机号" clearable v-model="searchText" @change="getFeedbackList"></el-input>
          <el-button slot="append" type="primary" icon="el-icon-search"  @click="getFeedbackList"></el-button>
        </div>
      </template>
    </NavTitle>
    <div class="pl20 pr20 mt16 pb20">
      <el-table :data="feedbackList" style="width: 100%">
        <el-table-column
          prop="userName"
          label="用户名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="联系方式">
        </el-table-column>
        <el-table-column
          prop="feedBackContent"
          label="内容">
        </el-table-column>
        <el-table-column
          prop="ch"
          label="渠道">
        </el-table-column>
        <el-table-column
          prop="model"
          label="机型">
        </el-table-column>
        <el-table-column
          prop="product"
          label="产品">
        </el-table-column>
        <el-table-column
          prop="prodVer"
          label="产品版本">
        </el-table-column>
        <el-table-column
          prop="ip"
          label="IP地址">
        </el-table-column>
        <el-table-column
          prop="gmtCreate"
          label="反馈时间">
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <span class="theme-text pointer" @click="openDetail(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="feedbackList.length > 0">
        <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <DetailPopup ref="detailPopupRef"></DetailPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import { queryFeedbackApi } from '@/api/admin.js'
import { dateFormatUtil } from '@/utils/util.js'
import DetailPopup from '../components/DetailPopup.vue'
import Pagination from '@/components/Pagination'
export default {
  name: 'FeedBack',
  components: {
    NavTitle,
    Pagination,
    DetailPopup
  },
  data() {
    return {
      feedbackList: [],
      searchText: '',
      curPage: 0
    }
  },
  created() {
    this.getFeedbackList()
  },
  methods: {
    getFeedbackList() {
      const formData = {
        startPage: this.curPage,
        pageSize: 10,
        searchText: this.searchText.trim()
      }
      queryFeedbackApi(formData).then(res => {
        console.log('意见反馈', res)
        const list = res.data.feedBacks || []
        if(list.length != 0 || this.curPage == 0) {
          this.feedbackList = list.map(item => {
          item.gmtCreate = dateFormatUtil(item.gmtCreate)
          return item
        })
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    nextPage() {
      this.curPage++
      this.getFeedbackList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getFeedbackList()
    },
    openDetail(info) {
      this.$refs.detailPopupRef.open({detail: info})
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
</style>